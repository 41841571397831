import { Controller } from "@hotwired/stimulus"
import EsriMap from '../../esri_map';

const mapIdentifier = 'recommendedPlacesMapDesktop';

export default class extends Controller {
  static mapInstance = null;

  connect() {
    if (this.mapHasNotBeenInstantiated(mapIdentifier)) {
      this.instantiateMapAndRender(mapIdentifier);
    }
  }

  instantiateMapAndRender(mapIdentifier, delay=0) {
    console.log("Initiate and render map");
    this.constructor.mapInstance = new EsriMap(mapIdentifier);
    const mapElement = $(`#${mapIdentifier}`);
    const placesData = mapElement.attr('data-places');
    const parsedPlacesData = JSON.parse(placesData);
    this.constructor.mapInstance.renderPlaces(parsedPlacesData.places, delay);
  }

  mapHasNotBeenInstantiated(mapIdentifier) {
    return $('.leaflet-pane').length === 0 && $(`#${mapIdentifier}`).length > 0;
  }
}
