import { Controller } from "@hotwired/stimulus"

// This is a controller for reusable actions to be used throughout the application
export default class extends Controller {
  static targets = ["link"]
  
  shareLink(event) {
    event.preventDefault()
    const link = event.currentTarget.dataset.link
    navigator.clipboard.writeText(link).then(() => {
      alert("Link copied to clipboard!")
    }).catch(err => {
      console.error("Failed to copy link: ", err)
    })
  }
}
