import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'

export default class extends Controller {
  static targets = ["tab"]
  static values = {
    param: String,    // Name of the query parameter to update
    default: String,  // Default value for the parameter if none is selected
  };

  connect() {
    window.addEventListener('popstate', this.handlePopState.bind(this))
  }

  disconnect() {
    window.removeEventListener('popstate', this.handlePopState.bind(this))
  }

  handlePopState(event) {
    // Reload the page when the back button is pressed
    location.reload()
  }

  updateActiveTab() {
    // NOTE: This will update the active tab during a click event
    const paramName = this.data.get("param")
    const defaultValue = this.data.get("default")
    const paramValue = new URLSearchParams(window.location.search).get(paramName) || defaultValue
    this.tabTargets.forEach(tab => {
      tab.classList.toggle("active", tab.dataset.status === paramValue)
    })
  }

  updateUrl(event) {
     // NOTE: This method is needs to be run if the tabs on a page are not in a turbo frame. 
    const url = new URL(event.currentTarget.href)
    const paramName = this.data.get("param")
    const paramValue = url.searchParams.get(paramName)
    const newUrl = new URL(window.location)
    newUrl.searchParams.set(paramName, paramValue)
    history.pushState({}, '', newUrl)
    this.updateActiveTab()
  }

  updateUrlOnly(event) {
    //NOTE: This method should be used when the tabs are in a tubro frame. The active tab would get updated in the turbo frame
    event.preventDefault();
    const url = new URL(event.currentTarget.href);
    const newUrl = new URL(window.location);
    // Clear old search parameters
    newUrl.search = '';
    url.searchParams.forEach((value, key) => {
      newUrl.searchParams.set(key, value); // Update or add the parameter
    });
    history.pushState({}, '', newUrl);
  }

  getTurboSteam(event) {
    event.preventDefault()
    get(event.target.href, {
      contentType: "text/vnd.turbo-stream.html",
      responseKind: "turbo-stream",
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
    this.updateUrlOnly(event)
  }

  getTurboSteamWithActive(event) {
    event.preventDefault()
    get(event.target.href, {
      contentType: "text/vnd.turbo-stream.html",
      responseKind: "turbo-stream",
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
    this.updateUrlOnly(event)
    this.updateActiveTab()
  }
}






